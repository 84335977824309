import { defineNuxtPlugin } from '#app/nuxt'
import { LazyBlueButton, LazyDivider, LazyIncentivesFourColumnIcons, LazyFaq, LazyStacked, LazyFeature, LazyFullImage, LazyFullRichText, LazyBlankSpace, LazyBlueWorkerBanner, LazyRichText, LazyStepsGrid, LazyGrid, LazyGuideCard, LazyGuidesGrid, LazyAboutUsHero, LazyHero, LazyPartnerHero, LazyRoundedImageText, LazyServiceHero, LazyTextRoundedImage, LazyFullWidthImage, LazyGridList, LazyListItem, LazyLocationPage, LazyLocationsGrid, LazyPage, LazyOurStory, LazyPartnersGrid, LazyCards, LazyBookCTA, LazyHeaderSectionCentered, LazyKnowledgeHome, LazyLeftImageWithUsps, LazyLeftTextWithUsps, LazyNearMeHome, LazyTextImageLeft, LazyTextImageRight, LazyThreeUsps, LazyUspsGrid, LazyVerticalUsps, LazyServiceLocationPage, LazyServicesGrid, LazyServicesInCategories, LazySkipHireCalculator, LazySkipHireNearMeSizes, LazyWasteIcon, LazyWasteTypes, LazyCustomerTestimonial, LazyMicroReview, LazyTestimonials, LazyCircleGradient, LazyPurpleGradient } from '#components'
const lazyGlobalComponents = [
  ["BlueButton", LazyBlueButton],
["Divider", LazyDivider],
["IncentivesFourColumnIcons", LazyIncentivesFourColumnIcons],
["Faq", LazyFaq],
["Stacked", LazyStacked],
["Feature", LazyFeature],
["FullImage", LazyFullImage],
["FullRichText", LazyFullRichText],
["BlankSpace", LazyBlankSpace],
["BlueWorkerBanner", LazyBlueWorkerBanner],
["RichText", LazyRichText],
["StepsGrid", LazyStepsGrid],
["Grid", LazyGrid],
["GuideCard", LazyGuideCard],
["GuidesGrid", LazyGuidesGrid],
["AboutUsHero", LazyAboutUsHero],
["Hero", LazyHero],
["PartnerHero", LazyPartnerHero],
["RoundedImageText", LazyRoundedImageText],
["ServiceHero", LazyServiceHero],
["TextRoundedImage", LazyTextRoundedImage],
["FullWidthImage", LazyFullWidthImage],
["GridList", LazyGridList],
["ListItem", LazyListItem],
["LocationPage", LazyLocationPage],
["LocationsGrid", LazyLocationsGrid],
["Page", LazyPage],
["OurStory", LazyOurStory],
["PartnersGrid", LazyPartnersGrid],
["Cards", LazyCards],
["BookCTA", LazyBookCTA],
["HeaderSectionCentered", LazyHeaderSectionCentered],
["KnowledgeHome", LazyKnowledgeHome],
["LeftImageWithUsps", LazyLeftImageWithUsps],
["LeftTextWithUsps", LazyLeftTextWithUsps],
["NearMeHome", LazyNearMeHome],
["TextImageLeft", LazyTextImageLeft],
["TextImageRight", LazyTextImageRight],
["ThreeUsps", LazyThreeUsps],
["UspsGrid", LazyUspsGrid],
["VerticalUsps", LazyVerticalUsps],
["ServiceLocationPage", LazyServiceLocationPage],
["ServicesGrid", LazyServicesGrid],
["ServicesInCategories", LazyServicesInCategories],
["SkipHireCalculator", LazySkipHireCalculator],
["SkipHireNearMeSizes", LazySkipHireNearMeSizes],
["WasteIcon", LazyWasteIcon],
["WasteTypes", LazyWasteTypes],
["CustomerTestimonial", LazyCustomerTestimonial],
["MicroReview", LazyMicroReview],
["Testimonials", LazyTestimonials],
["CircleGradient", LazyCircleGradient],
["PurpleGradient", LazyPurpleGradient],
  
]

export default defineNuxtPlugin({
  name: 'nuxt:global-components',
  setup (nuxtApp) {
    for (const [name, component] of lazyGlobalComponents) {
      nuxtApp.vueApp.component(name, component)
      nuxtApp.vueApp.component('Lazy' + name, component)
    }
  }
})
